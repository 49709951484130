import React, { useState } from "react";
import { makeAxiosCall } from "../utils/utils.js";
import { UserInterface } from "../interfaces/User";

interface UserContextInterface {
  user: UserInterface;
  setUser: (user: UserInterface) => void;
  tokenExpired: boolean;
  setTokenExpired: (tokenExpired: boolean) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  token: string;
  setToken: (token: string) => void;
}

export const UserContext = React.createContext<UserContextInterface>(null);

interface UserProviderProps {
  children: any;
}

const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserInterface>();
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [info, setInfo] = useState();

  React.useEffect(() => {
    getUrlParams();
  }, []);

  const getUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramToken = urlParams.get("token");

    if (paramToken) {
      getCustomerInfo(paramToken);
    } else {
      getCustomerInfo();
    }
  }

  const getCustomerInfo = (token?) => {
    makeAxiosCall("get", `/customer-info`, null, token ? token : null)
      .then((res) => {
        setUser({ ...res.data });
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoggedIn(false);
      });

  };
  React.useEffect(() => {
    console.log("user", user)
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        tokenExpired,
        setTokenExpired,
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;